/* General li styles*/
.main-content ul,
.main-content ol{
	padding-left: 40px;
}

.main-content ul.mapplic-list-container {
	padding-left: 0;
}

.main-content li {
	list-style: inherit;
}

.main-content .md-image-map__areas li {
	list-style: none;
}

.main-content p,
.main-content ul,
.main-content ol{
	margin-top: 1em;
	margin-bottom: 1em;
}

.main-content h1,
.main-content .h1,
.main-content .biggest, .awards__intro h1 {
	font-size: 36px;
	font-weight:normal;
	margin: 0.67em 0;
	font-family:"Frutiger Next Bold", "Source Sans Pro Bold", Arial;
}

.main-content h2,
.main-content .h2,
.main-content .bigger {
	font-size: 1.5em;
	margin-top: 0.83em;
	margin-bottom: 0.83em;
}

.main-content h3,
.main-content .h3,
.main-content .big {
	font-size: 1.17em;
	margin-top: 1em;
	margin-bottom: 1em;
}

.main-content h4,
.main-content .h4,
.main-content .small {
	margin-top: 1.33em;
	margin-bottom: 1.33em;
}

.main-content h5,
.main-content .h5,
.main-content .smaller {
	font-size: 0.83em;
	margin-top: 1.67em;
	margin-bottom: 1.67em;
}

.main-content h6,
.main-content .h6,
.main-content .smallest {
	font-size: 0.67em;
	margin-top: 2.33em;
	margin-bottom: 2.33em;
}

.row {
	.clearfix();
	margin-left: -20px;
	margin-right: -20px;
}

[class*=col-] {
	position: relative;
	min-height: 1px;
	padding-right: 20px;
	padding-left: 20px;
	float: left;
	width: 25%;
/*	padding-bottom: 20px;
	padding-top: 20px;*/

}

.col-1 {
	width: 25%;
}

.col-2 {
	width: 50%;
	/*padding-bottom: 10px;*/
}

.col-3 {
	width: 75%;
	/*padding-bottom: 10px;*/
}

.col-4 {
	width: 100%;
	float: none;
	/*padding-bottom: 10px;*/
}

each(range(10, 100, 10), {
	.col-@{value} {
		width: (@value * 1%);

		@media @medium-devices-and-smaller {
			width: 100%;
			float: none;
		}
	}
});

.col-15,
.col-33 {
	width: 33.33%;

	@media @medium-devices-and-smaller {
		width: 100%;
		float: none;
	}
}

.col-66 {
	width: 66.66%;

	@media @medium-devices-and-smaller {
		width: 100%;
		float: none;
	}
}

@media @medium-devices-and-smaller {
	.main-content > .container > .row > div.col-1:first-child{
		display:none;
	}
}

@media @only-medium-devices {
	.col-2, .col-3, .col-4 {
		width: 100%;
		float: none;
	}

	.col-3, .col-4 {
		//clear: both; Auskommentiert wegen WCMCW-1040
	}

	.col-1{
		width: 50%;
		float:left;
	}
	footer .col-1 ~ .col-1 ~ .col-1{
		clear:left;
	}
	footer .col-1 ~ .col-1 ~ .col-1 ~ .col-1{
		clear:none;
	}
	.col-3 .col-1{
		width: 25%;
		float:left;
	}
	.col-3 .col-2{
		width: 50%;
		float:left;
	}
	.col-3 .col-3{
		width: 75%;
		float:left;
	}
	.col-3 .col-4{
		width: 100%;
		float:left;
	}
}

@media @only-small-devices{
	.row {

		margin-left: 0;
		margin-right: 0;
	}

	[class^=col-] {

		padding-right: 0;
		padding-left: 0;


	}
	.col-1, .col-2, .col-3, .col-4 {
		width: 100%;
		float: none;
	}

	.col-3 .col-1{
		width: 50%;
		float:left;
	}
	.col-3 .col-1 ~ .col-1 ~ .col-1{
		clear:left;
	}
	.col-3 .col-1 ~ .col-1 ~ .col-1 ~ .col-1{
		clear:none;
	}
	.col-3 .col-3{
		width: 50%;
		float:left;
	}
	.col-3 .content .col-3 ~ .col-3 ~ .col-3{
		clear:left;
	}
	.col-3 .content .col-3 ~ .col-3 ~ .col-3 ~ .col-3{
		clear:none;
	}
}

@media @only-small-devices{
	.col-1, .col-2, .col-3, .col-4 {
		width: 100%;
		float: none;
	}

	.col-3 .col-1{
		width: 100%;
		float:none;
	}
	.col-3 .col-3{
		width: 100%;
		float:none;
	}
}

@media @only-mini-devices{
	.col-3 .col-1,.col-3 .col-3{
		width: 100%;
		float:left;
	}
}

.grid-test {
	min-height: 20px;
	padding: 10px;
	background: @black-10;
}

.col--right {
	text-align: right;
}
